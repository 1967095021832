<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="processes"
          :fields="process.tableFields"
          show-empty
          responsive
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge variant="info">{{ data.value }}</b-badge>
          </template>
          <template v-slot:cell(parent)="data">
            <b-link
              v-if="data.value"
              :to="{
                name: 'show-job-process',
                params: {
                  id: data.value.id,
                  slug: data.value.slug || 'abcde',
                },
              }"
              >{{ data.value.name }}</b-link
            >
            <span v-else>-</span>
          </template>

          <template v-slot:cell(action)="data">
            <span v-html="data.value"></span>
          </template>

          <template v-slot:cell(process_code)="data">
            <div>
              <span
                style="text-decoration: underline; color: blue; cursor: pointer"
              >
                PM-{{ data.item.id }}
              </span>
            </div>
          </template>

          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(deadline)="data">
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
              "
            >
              <div
                style="
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: green;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <p style="position: relative; top: 8px; color: white">
                  {{ data.value }}
                </p>
              </div>
            </div>
          </template>

          <template v-slot:cell(responsable)="data">
            <TableResponsable :responsable="data.value" />
          </template>

          <template v-slot:cell(businessOwner)="data">
            <TableResponsable :responsable="data.value" />
          </template>

          <template v-slot:cell(capaciteMetiers)="data">
            <div class="text-center">
              <span v-if="data.value">{{ data.value.name }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="['delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @cancelItem="removeTeamInputs(data.index)"
              @deleteItem="deleteItem(data.item.id)"
            ></table-actions>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import Swal from "sweetalert2";

export default {
  components: { TableActions, TableResponsable },
  props: {
    processes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    process: {
      tableFields: [
        { key: "process_code", label: "Code", sortable: true },
        { key: "name", label: "Processus", sortable: true },
        { key: "capaciteMetiers", label: "Capacité Métier", sortable: true },
        { key: "businessOwner", label: "Business Owner", sortable: true },

        { key: "taux", label: "Taux de remplissage", sortable: true },

        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
    },
  }),

  methods: {
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        // text: "Cette opération est irréversible et entraînera la suppréssion de tous les processus rattachées!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("processusMetier/updateProcessusMetier", {
              id: id,
              parent: null,
              niveau: 1,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "processusMetier/fetchProcessusMetier",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
};
</script>

<style></style>
