<template>
  <div>
    <div class="row">
      <div
        class="
          col-12
          d-flex
          flex-wrap
          justify-content-between
          align-items-center
        "
      >
        <p class="text-secondary my-auto font-weight-bold">
          Liste des objets manipulés
        </p>
        <CustomAddButton
          class="justify-content-end"
          @click="addItem"
          :disabled="disabled || !(isAdmin || isSuper || isArchitect)"
          text=" Nouvel objet manipulé"
        ></CustomAddButton>
      </div>

      <div class="col-12">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>

      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="process.tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          responsive
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(concept)="data">
            <b-link
              :to="{
                name: 'show-concept',
                params: {
                  id: data.value.id,
                  slug: data.value.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
              >{{ data.value ? data.value.name : "-" }}</b-link
            >
            <v-select
              v-else
              appendToBody
              label="text"
              :reduce="({ value }) => value"
              :options="conceptsList"
              v-model="concept"
              placeholder="Concept"
              @input="objet = null"
            ></v-select>
          </template>
          <template v-slot:cell(business_object)="data">
            <b-link
              :to="{
                name: 'show-business-object',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              {{ data.item ? data.item.name : "-" }}
            </b-link>
            <v-select
              v-else
              appendToBody
              label="text"
              :options="objectsList"
              :reduce="({ value }) => value"
              v-model="objet"
              placeholder="Objet"
            ></v-select>
          </template>
          <template v-slot:cell(sensitivity)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <b-badge :variant="sensible(data.item) ? 'danger' : 'success'">{{
                sensible(data.item)
              }}</b-badge>
            </span>
            <div v-else-if="selectedObject">
              <b-badge
                :variant="sensible(selectedObject) ? 'danger' : 'success'"
                >{{ sensible(selectedObject) }}</b-badge
              >
            </div>
            <span v-else>- </span>
          </template>
          <template v-slot:cell(dcp)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-badge
                  :variant="dcp(data.item) === 'Oui' ? 'danger' : 'success'"
                  >{{ dcp(data.item) }}</b-badge
                >
              </span>
            </span>
            <div v-else-if="selectedObject">
              <b-badge
                :variant="dcp(selectedObject) === 'Oui' ? 'danger' : 'success'"
                >{{ dcp(selectedObject) }}</b-badge
              >
            </div>
            <span v-else>- </span>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="['delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem"
              @deleteItem="deleteItem(data.item)"
            ></table-actions>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <!-- Add new Application -->
    <div to="modals-xyz-548" v-show="showAddManipulatedObject">
      <Modal @close="showAddManipulatedObject = !showAddManipulatedObject">
        <AddManipulatedObjects />
      </Modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Modal from "@/components/custom/Modal.vue";
import AddManipulatedObjects from "./AddManipulatedObjects.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import { maxBy } from "lodash";
import Swal from "sweetalert2";
export default {
  components: { TableActions, Modal, AddManipulatedObjects, CustomAddButton },
  props: {
    objects: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    concept: null,
    objet: null,
    currentPage: 1,
    perPage: 5,
    sortDesc: false,
    process: {
      tableFields: [
        { key: "concept", label: "Concept", sortable: true },
        { key: "business_object", label: "Objet Métier", sortable: true },
        { key: "dcp", label: "DCP", sortable: true },
        { key: "sensitivity", label: "Sensibilité", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    showAddManipulatedObject: false,
    tableItems: [],
  }),
  methods: {
    addItem() {
      if (this.isAdmin || this.isSuper || this.isArchitect) {
        var data = this.tableItems.some((objet) => objet.editMode);
        if (data == false) {
          this.currentPage = 1;
          this.tableItems.unshift({ editMode: true });
        }
      }
    },
    dcp(objet) {
      return !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) => attribut.dcp === "Non")
        ? "Non"
        : "Oui";
    },
    sensible(objet) {
      return !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) => attribut.sensible == 0)
        ? 0
        : maxBy(objet.attributs, (attribut) => attribut.sensible).sensible;
    },
    confirmItem() {
      if (this.isAdmin || this.isSuper || this.isArchitect) {
        if (this.objet) true;
        var objets = this.PROCESSUS_METIER.objets.map((objet) => objet["@id"]);
        objets.push(this.objet);
        this.$store
          .dispatch("processusMetier/updateProcessusMetier", {
            id: this.$route.params.id,
            objets: objets,
          })
          .then(() => {
            this.$store.dispatch(
              "processusMetier/fetchAllProcessusMetierObjets",
              this.$route.params.id
            );
            this.$store.dispatch(
              "processusMetier/fetchProcessusMetier",
              this.$route.params.id
            );
          })
          .then(() => {
            Swal.fire("L'objet a été bien créé !", "", "success");
            this.objet = null;
            this.concept = null;
          });
      }
    },
    cancelItem() {
      if (this.objet || this.concept) {
        this.objet = null;
        this.concept = null;
      } else {
        this.$store.dispatch(
          "processusMetier/fetchAllProcessusMetierObjets",
          this.$route.params.id
        );
      }
    },
    deleteItem(item) {
      if (this.isAdmin || this.isSuper || this.isArchitect) {
        Swal.fire({
          title: "Etes-vous sûr(e) ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Confirmer",
        }).then((result) => {
          if (result.value) {
            var objets = this.PROCESSUS_METIER.objets.map(
              (objet) => objet["@id"]
            );
            objets = objets.filter((objet) => objet !== item["@id"]);
            this.$store
              .dispatch("processusMetier/updateProcessusMetier", {
                id: this.$route.params.id,
                objets: objets,
              })
              .then(() => {
                this.$store
                  .dispatch(
                    "processusMetier/fetchAllProcessusMetierObjets",
                    this.$route.params.id
                  )
                  .then(() => {
                    Swal.fire("Supprimé!", "", "success");
                  });
              });
          }
        });
      }
    },
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("objet/fetchAllObjets");
    this.$store.dispatch("concept/fetchAllConcepts");
    this.$store
      .dispatch(
        "processusMetier/fetchAllProcessusMetierObjets",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });
  },

  computed: {
    ...mapGetters("processusMetier", ["OBJECTS"]),
    ...mapGetters("concept", ["CONCEPTS"]),
    ...mapGetters("objet", ["OBJETS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIER"]),

    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    conceptsList() {
      return this.CONCEPTS.map((concept) => ({
        text: concept.name,
        value: concept["@id"],
      }));
    },
    objectsList() {
      return this.CONCEPTS.find((cncpt) => cncpt["@id"] === this.concept)
        ? this.CONCEPTS.find((cncpt) => cncpt["@id"] === this.concept)
            .objets.map((objet) => ({
              text: objet.name,
              value: objet["@id"],
            }))
            .filter(
              (objet) =>
                !this.tableItems.find((item) => item["@id"] === objet.value)
            )
        : [];
    },
    selectedObject() {
      return this.objet
        ? this.OBJETS.find((obj) => obj["@id"] === this.objet)
        : null;
    },
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    OBJECTS() {
      this.tableItems = this.OBJECTS.objets;
    },
  },
};
</script>

<style></style>
