var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.processes,"fields":_vm.process.tableFields,"show-empty":"","responsive":"","bordered":"","striped":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(parent)",fn:function(data){return [(data.value)?_c('b-link',{attrs:{"to":{
              name: 'show-job-process',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }}},[_vm._v(_vm._s(data.value.name))]):_c('span',[_vm._v("-")])]}},{key:"cell(action)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(process_code)",fn:function(data){return [_c('div',[_c('span',{staticStyle:{"text-decoration":"underline","color":"blue","cursor":"pointer"}},[_vm._v(" PM-"+_vm._s(data.item.id)+" ")])])]}},{key:"cell(taux)",fn:function(data){return [_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background:${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              };font-size:8px;`),attrs:{"value":data.value,"label":`${data.value}%`}})],1)]}},{key:"cell(deadline)",fn:function(data){return [_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"width":"20px","height":"20px","border-radius":"10px","background":"green","display":"flex","align-items":"center","justify-content":"center"}},[_c('p',{staticStyle:{"position":"relative","top":"8px","color":"white"}},[_vm._v(" "+_vm._s(data.value)+" ")])])])]}},{key:"cell(responsable)",fn:function(data){return [_c('TableResponsable',{attrs:{"responsable":data.value}})]}},{key:"cell(businessOwner)",fn:function(data){return [_c('TableResponsable',{attrs:{"responsable":data.value}})]}},{key:"cell(capaciteMetiers)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.value)?_c('span',[_vm._v(_vm._s(data.value.name))]):_c('span',[_vm._v("-")])])]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"cancelItem":function($event){return _vm.removeTeamInputs(data.index)},"deleteItem":function($event){return _vm.deleteItem(data.item.id)}}})]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }