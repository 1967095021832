<template>
  <div>
    <div class="row">
      <div
        class="
          col-12
          d-flex
          flex-wrap
          justify-content-between
          align-items-center
        "
      >
        <p class="text-secondary my-auto font-weight-bold">
          Liste des applications supportant le processus
        </p>

        <CustomAddButton
          @click="addItem"
          :disabled="disabled || !(isAdmin || isSuper || isArchitect)"
          text="Ajouter une application"
        ></CustomAddButton>
      </div>
      <div class="col-12">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
      <div class="col-12">
        <!-- {{ PROCESSUS_APPLICATIONS.applications }} -->
        <b-table
          :items="applications"
          :fields="process.tableFields"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          responsive
          bordered
          striped
          :busy="loading"
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(po)="data">
            <TableResponsable
              v-if="!data.item.hasOwnProperty('editMode')"
              :responsable="data.value"
            />
            <TableResponsable v-else :responsable="newApplication.po" />
          </template>
          <template v-slot:cell(filling_rate)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :variant="
                  data.value > 70
                    ? 'success'
                    : data.value > 30
                    ? 'warning'
                    : 'danger'
                "
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(capaciteSis)="data">
            <div
              v-if="!data.item.hasOwnProperty('editMode')"
              class="text-center"
            >
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ data.value.length }}
              </b-badge>
            </div>
            <div v-else class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ newApplication.capaciteSis }}
              </b-badge>
            </div>
          </template>

          <template v-slot:cell(id)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              <span
                style="text-decoration: underline; color: blue; cursor: pointer"
              >
                SA{{ data.value }}
              </span>
            </div>
            <b-form-input
              v-else
              disabled
              v-model="newApplication.id"
              placeholder="ref"
              type="text"
            ></b-form-input>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-application',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              >{{ data.value }}
            </b-link>
            <v-select
              v-else
              label="text"
              :reduce="(text) => text.value"
              v-model="newApplication.name"
              :options="applicationsList"
              placeholder="Application"
              @input="changedata"
              appendToBody
              type="text"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress
              v-if="!data.item.hasOwnProperty('editMode')"
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
            <b-progress
              v-else
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="`background:${
                  newApplication.taux > 20 && newApplication.taux <= 70
                    ? '#f5f245'
                    : newApplication.taux > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="newApplication.taux"
                :label="`${newApplication.taux}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge
              v-if="!data.item.hasOwnProperty('editMode')"
              variant="info"
              >{{ data.value }}</b-badge
            >
            <v-select
              disabled
              :options="['En prod', 'En cours', 'Terminé']"
              v-else
              v-model="newApplication.status"
              placeholder="Statut"
              type="text"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="
                isAdmin || isSuper || isArchitect
                  ? [
                      'delete',
                      data.item.hasOwnProperty('editMode') ? '' : 'transfer',
                    ]
                  : []
              "
              :editMode="data.item.hasOwnProperty('editMode')"
              @showItem="$emit('showItem', data.item)"
              @cancelItem="cancelItem(data.item, data.index)"
              @confirmItem="addData"
              @deleteItem="deleteItem(data.item.id)"
              @transferItem="transfer(data.item.id, data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <!-- Add new Application -->
    <div to="modals-xyz-548" v-show="showApplicationModal">
      <Modal @close="showApplicationModal = !showApplicationModal">
        <add-application />
      </Modal>
    </div>
    <div to="modals-xyz-548" v-if="applicationspup">
      <Modal @close="applicationspup = !applicationspup">
        <TransferApplication :libelle="libelle" @confirmApp="transferApp" />
      </Modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import AddApplication from "./AddApplication.vue";
import Modal from "@/components/custom/Modal.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { APPLICATION_URL } from "@/helpers/constURL";
import TransferApplication from "./TransferApplication.vue";
export default {
  components: {
    TransferApplication,
    CustomAddButton,
    TableResponsable,
    TableActions,
    Modal,
    AddApplication,
  },
  props: {
    // applications: {
    //   type: Array,
    //   default: () => [],
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    perPage: 10,
    APPLICATION_URL,
    currentPage: 1,
    sortDesc: false,
    idApplication: null,
    applicationspup: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    libelle: "",
    newApplication: {
      name: "",
      capaciteSis: 0,
      id: "",
      status: "",
      po: "",
      taux: 0,
    },
    process: {
      tableFields: [
        { key: "id", label: "Ref", sortable: true },
        { key: "name", label: "Application", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "capaciteSis", label: "Capacité SI", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
    },
    showApplicationModal: false,
    loading: true,
  }),
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("application/fetchAllApplications");
      this.$store
        .dispatch(
          "processusMetier/fetchAllProcessusMetierApplications",
          this.$route.params.id
        )
        .then(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("application", ["APPLICATION", "APPLICATIONS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    ...mapGetters("processusMetier", {
      PROCESSUS_APPLICATIONS: "APPLICATIONS",
    }),

    applicationsList() {
      return this.APPLICATIONS.map((data) => ({
        text: data.name,
        value: data.id,
      })).filter(
        (data) =>
          !this.applications.find(
            (application) => application.id === data.value
          )
      );
    },
    applications() {
      return this.$route.params.id
        ? this.PROCESSUS_APPLICATIONS.applications
        : [];
    },

    rows() {
      return this.applications.length;
    },
  },
  methods: {
    transfer(id, data) {
      (this.idApplication = id), (this.libelle = data.name);
      this.applicationspup = !this.applicationspup;
    },
    cancelItem: function () {
      if (this.newApplication.name == "") {
        this.applications.splice(0, 1);
      } else {
        this.newApplication.name = "";
        this.newApplication.id = "";
        this.newApplication.taux = 0;
        this.newApplication.status = "";
        this.newApplication.capaciteSis = 0;
        this.newApplication.po = "";
      }
    },
    changedata() {
      if (!this.newApplication.name) {
        this.newApplication.name = "";
        this.newApplication.id = "";
        this.newApplication.taux = 0;
        this.newApplication.status = "";
        this.newApplication.capaciteSis = 0;
        this.newApplication.po = "";
      } else {
        this.$store
          .dispatch("application/fetchApplication", this.newApplication.name)
          .then(() => {
            this.newApplication.status = this.APPLICATION.status;
            this.newApplication.capaciteSis =
              this.APPLICATION.capaciteSis.length;
            this.newApplication.id = "SA" + this.APPLICATION.id;
            this.newApplication.po = this.APPLICATION.po;
            this.newApplication.taux = this.APPLICATION.taux;
          });
      }
    },
    addItem() {
      if (this.isAdmin || this.isSuper || this.isArchitect) {
        var data = this.applications.some((contribute) => contribute.editMode);
        if (data == false) {
          this.applications.unshift({ editMode: true });
        }
      }
    },
    transferApp(data) {
      this.applicationspup = !this.applicationspup;
      var app = data.applications.map((data) => data["@id"]);
      app.push(`/api/${APPLICATION_URL}/${this.idApplication}`);
      var item = {
        id: data.id,
        applications: app,
      };
      this.$store
        .dispatch("processusMetier/updateProcessusMetier", item)
        .then(() => {
          this.$store
            .dispatch(
              "processusMetier/fetchAllProcessusMetierApplications",
              this.$route.params.id
            )
            .then(() => {});
        });
      var idapp = this.applications.filter(
        (data) => data.id !== this.idApplication
      );
      idapp = idapp.map((data) => data["@id"]);
      var items = {
        id: this.$route.params.id,
        applications: idapp,
      };
      this.$store
        .dispatch("processusMetier/updateProcessusMetier", items)
        .then(() => {
          this.$store
            .dispatch(
              "processusMetier/fetchAllProcessusMetierApplications",
              this.$route.params.id
            )
            .then(() => {
              Swal.fire("L'application a été bien transférée !", "", "success");
            });
        });
    },
    addData() {
      var app = this.applications.map((data) => data["@id"]);
      app.push(`/api/${APPLICATION_URL}/${this.newApplication.name}`);
      app.splice(0, 1);
      var item = {
        id: this.$route.params.id,
        applications: app,
      };
      this.$store
        .dispatch("processusMetier/updateProcessusMetier", item)
        .then(() => {
          this.$store
            .dispatch(
              "processusMetier/fetchAllProcessusMetierApplications",
              this.$route.params.id
            )
            .then(() => {
              Swal.fire("L'application a été bien créée !", "", "success");
            });
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var app = this.applications.filter((data) => data.id !== id);
          app = app.map((data) => data["@id"]);
          var item = {
            id: this.$route.params.id,
            applications: app,
          };
          this.$store
            .dispatch("processusMetier/updateProcessusMetier", item)
            .then(() => {
              this.$store
                .dispatch(
                  "processusMetier/fetchAllProcessusMetierApplications",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
};
</script>

