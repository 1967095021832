<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="h2">Nouvelle application</p>
        <hr />
      </div>
    </div>
    <b-form class="row">
      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Ref:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              type="text"
              placeholder="Libellé initiative"
              required
            ></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Libellé:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Libellé" required></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Capacité SI:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Capacité si" required></b-form-input>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>PO:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="PO" required></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Taux de remplissage:</label>
          </div>
          <div class="col-md-8">
            <simple-slider></simple-slider>
          </div>
        </div>
      </div>

      <div class="col-12">
        <hr />
        <b-button variant="secondary">Confirmer</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";

export default {
  components: {
    SimpleSlider,
  },
};
</script>

<style></style>
