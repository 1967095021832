var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"text-secondary my-auto font-weight-bold"},[_vm._v(" Liste des applications supportant le processus ")]),_c('CustomAddButton',{attrs:{"disabled":_vm.disabled || !(_vm.isAdmin || _vm.isSuper || _vm.isArchitect),"text":"Ajouter une application"},on:{"click":_vm.addItem}})],1),_vm._m(0),_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.applications,"fields":_vm.process.tableFields,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"responsive":"","bordered":"","striped":"","busy":_vm.loading},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(po)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('TableResponsable',{attrs:{"responsable":data.value}}):_c('TableResponsable',{attrs:{"responsable":_vm.newApplication.po}})]}},{key:"cell(filling_rate)",fn:function(data){return [_c('b-progress',{staticStyle:{"background-color":"#cacccd !important"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{attrs:{"variant":data.value > 70
                  ? 'success'
                  : data.value > 30
                  ? 'warning'
                  : 'danger',"value":data.value,"label":`${data.value}%`}})],1)]}},{key:"cell(capaciteSis)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(data.value.length)+" ")])],1):_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(_vm.newApplication.capaciteSis)+" ")])],1)]}},{key:"cell(id)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('div',[_c('span',{staticStyle:{"text-decoration":"underline","color":"blue","cursor":"pointer"}},[_vm._v(" SA"+_vm._s(data.value)+" ")])]):_c('b-form-input',{attrs:{"disabled":"","placeholder":"ref","type":"text"},model:{value:(_vm.newApplication.id),callback:function ($$v) {_vm.$set(_vm.newApplication, "id", $$v)},expression:"newApplication.id"}})]}},{key:"cell(name)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{attrs:{"to":{
              name: 'show-application',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }}},[_vm._v(_vm._s(data.value)+" ")]):_c('v-select',{attrs:{"label":"text","reduce":(text) => text.value,"options":_vm.applicationsList,"placeholder":"Application","appendToBody":"","type":"text"},on:{"input":_vm.changedata},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.newApplication.name),callback:function ($$v) {_vm.$set(_vm.newApplication, "name", $$v)},expression:"newApplication.name"}})]}},{key:"cell(taux)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-progress',{staticStyle:{"background-color":"#cacccd !important"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background:${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              };font-size:8px;`),attrs:{"value":data.value,"label":`${data.value}%`}})],1):_c('b-progress',{staticStyle:{"background-color":"#cacccd !important"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background:${
                _vm.newApplication.taux > 20 && _vm.newApplication.taux <= 70
                  ? '#f5f245'
                  : _vm.newApplication.taux > 70
                  ? '#23d92c'
                  : '#f5ba45'
              };font-size:8px;`),attrs:{"value":_vm.newApplication.taux,"label":`${_vm.newApplication.taux}%`}})],1)]}},{key:"cell(status)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(data.value))]):_c('v-select',{attrs:{"disabled":"","options":['En prod', 'En cours', 'Terminé'],"placeholder":"Statut","type":"text"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.newApplication.status),callback:function ($$v) {_vm.$set(_vm.newApplication, "status", $$v)},expression:"newApplication.status"}})]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":_vm.isAdmin || _vm.isSuper || _vm.isArchitect
                ? [
                    'delete',
                    data.item.hasOwnProperty('editMode') ? '' : 'transfer',
                  ]
                : [],"editMode":data.item.hasOwnProperty('editMode')},on:{"showItem":function($event){return _vm.$emit('showItem', data.item)},"cancelItem":function($event){return _vm.cancelItem(data.item, data.index)},"confirmItem":_vm.addData,"deleteItem":function($event){return _vm.deleteItem(data.item.id)},"transferItem":function($event){return _vm.transfer(data.item.id, data.item)}}})]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showApplicationModal),expression:"showApplicationModal"}],attrs:{"to":"modals-xyz-548"}},[_c('Modal',{on:{"close":function($event){_vm.showApplicationModal = !_vm.showApplicationModal}}},[_c('add-application')],1)],1),(_vm.applicationspup)?_c('div',{attrs:{"to":"modals-xyz-548"}},[_c('Modal',{on:{"close":function($event){_vm.applicationspup = !_vm.applicationspup}}},[_c('TransferApplication',{attrs:{"libelle":_vm.libelle},on:{"confirmApp":_vm.transferApp}})],1)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }