<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Transfert application :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0"
        >Recherche Processus métiers :</span
      >
      <b-form-input placeholder="Processus métiers" v-model="application" />
      <b-button
        class="ml-1"
        @click="$emit('confirmApp', selectedRole) && $emit('close')"
        :disabled="selectedRole === null"
        type=""
      >
        Confirmer
      </b-button>
    </div>

    <b-table
      :fields="fields"
      :items="applications"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedRole"
            :value="data.item"
          ></b-form-radio>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    libelle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedRole: null,
      selectedUser: null,
      loadingData: true,
      application: "",
      searchQuery: {
        role: "",
      },
      fields: [
        { key: "name", label: "Pocessus " },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch("processusMetier/fetchAllProcessusMetiers")
      .then(() => (this.loadingData = false));
  },
  computed: {
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),
    applications() {
      return this.PROCESSUS_METIERS.filter(
        (pro) =>
          pro.name.toLowerCase().indexOf(this.application.toLowerCase()) > -1
      );
    },
    rows() {
      return this.PROCESSUS_METIERS.length;
    },
  },
};
</script>

<style></style>
